import React, { useEffect, useState, useContext } from 'react';
import Helmet from 'react-helmet';
import Section from '@latitude/section';
import Layout from '@/components/layout';
import AchPageHeaderSection from './_ach-page-header';
import LowRateMicrositeHeader from './../_low-rate-header';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import queryString from 'query-string';

const urlVixVerify =
  'https://au.vixverify.com/sa/customers/gecapital-ach/home.seam?ui=lowrate';

function AchAdditionalDetailsPage(props) {
  const [urlFrame, setUrlFrame] = useState(null);

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  let applicationId = null;
  let queryParameters = null;

  if (typeof window !== 'undefined') {
    queryParameters = new URLSearchParams(window.location.search);
    applicationId = queryParameters.get('applicationid');

    if (applicationId == null) {
      window.location.replace('/credit-cards/low-rate/addcard/');
    }
  }

  function fetchIframeSource() {
    if (window?.location?.search) {
      const queryParameters = new URLSearchParams(window.location.search);
      const applicationId = queryParameters.get('applicationid');

      if (applicationId) {
        return urlVixVerify + '&applicationid=' + applicationId;
      }
    }
    return urlVixVerify;
  }

  useEffect(() => {
    const iframeSource = fetchIframeSource();
    setUrlFrame(iframeSource);
  }, []);

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/low-rate/addcard/ach-form/"
          />
          <meta
            name="description"
            content="Add an additional cardholder to your Latitude Low Rate Mastercard"
          />
          <title>Additional Card Holder Details | Low Rate Mastercard</title>
        </Helmet>
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <AchPageHeaderSection />
        )}
        <LowRateMicrositeHeader />
        <Section>
          <div
            css={{ clear: 'both', margin: '10px 0' }}
            className="lfs-iframe-vixverify"
          >
            {urlFrame && (
              <iframe
                css={{
                  width: '100%',
                  minHeight: '800px',
                  border: 'none',
                  padding: '10px'
                }}
                title="Verify Your Identity"
                src={urlFrame}
              />
            )}
          </div>
        </Section>
      </main>
    </Layout>
  );
}

export default AchAdditionalDetailsPage;
